import React, { useEffect, useState } from 'react';
import { Header, Input, Pagination, Field, Message, Button } from 'react-frontier';
import { Modal, Dropdown, ModalActions } from 'semantic-ui-react';
import { RFC, Location} from '../Classes';
import API from '../API';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import { bindFormChange } from '../Util';

interface TaxModalProps {
	open: boolean,
	onClose: () => void,
	title?: string,
	onCreate?: (rfcForm: RFC) => void,
	deleted: number;
}

const regimenList = [
   { text: 'Sueldos y Salarios e Ingresos Asimilados a Salarios', value: '605' },
   { text: 'Arrendamiento', value: '606' },
   { text: 'Demás ingresos', value: '608' },
   { text: 'Ingresos por Dividendos (socios y accionistas)', value: '611' },
   { text: 'Personas Físicas con Actividades Empresariales y Profesionales', value: '612' },
   { text: 'Ingresos por intereses', value: '614' },
   { text: 'Régimen de los ingresos por obtención de premios', value: '615' },
   { text: 'Sin obligaciones fiscales', value: '616' },
   { text: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras', value: '622' },
   { text: 'Incorporación Fiscal', value: '621' },
   { text: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales', value: '629' },
   { text: 'Enajenación de acciones en bolsa de valores', value: '630' },
   { text: 'General de ley personas morales', value: '601' },
   { text: 'Régimen Simplificado de Confianza', value: '626' }
]

const cfdiList = [
   { text: 'Adquisición de mercancías', value: 'G01' },
   { text: 'Devoluciones, descuentos o bonificaciones', value: 'G02' },
   { text: 'Gastos en general', value: 'G03' },
   { text: 'Construcciones', value: 'I01' },
   { text: 'Mobiliario y equipo de oficina por inversiones', value: 'I02' },
   { text: 'Equipo de transporte', value: 'I03' },
   { text: 'Equipo de cómputo y accesorios', value: 'I04' },
   { text: 'Dados, troqueles, moldes, matrices y herramental', value: 'I05' },
   { text: 'Comunicaciones telefónicas', value: 'I06' },
   { text: 'Comunicaciones satelitales', value: 'I07' },
   { text: 'Otra maquinaria y equipo', value: 'I08' },
   { text: 'Honorarios médicos, dentales y gastos hospitalarios', value: 'D01' },
   { text: 'Gastos médicos por incapacidad o discapacidad', value: 'D02' },
   { text: 'Gastos funerales', value: 'D03' },
   { text: 'Donativos', value: 'D04' },
   { text: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)', value: 'D05' },
   { text: 'Aportaciones voluntarias al SAR', value: 'D06' },
   { text: 'Primas por seguros de gastos médicos', value: 'D07' },
   { text: 'Gastos de transportación escolar obligatoria', value: 'D08' },
   { text: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones', value: 'D09' },
   { text: 'Pagos por servicios educativos (colegiaturas)', value: 'D10' },
   { text: 'Pagos', value: 'CP01' },
   { text: 'Nómina', value: 'CN01' },    
   { text: 'Sin Efectos Fiscales', value: 'S01' },              
]

var initateForm: RFC = {
   tax_id: null,
	legal_name: null,
	rfc: null,
	email: null,
	deleted: false,
	regimen: null,
	use_cfdi: null,
	name: null,
	location:{
		state: null,
		street: null,
		zipcode: null,
		city: null,
		phone: null,
		country: null,
		comments: null,
		exterior_number: null,
		interior_number: null,
		name: null,
		neighborhood: null,
	}
}

var TaxModal = (props: TaxModalProps) =>{
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [rfcForm, setRfcForm] = useState<RFC>(initateForm);
	var [location, setLocation] = useState<Location>(initateForm.location)

	var create = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(rfcForm, {
			legal_name: [{
			  rule: 'minLength', params: [3], label: 'Razón social'
			}],
			email: [{
			  rule: 'email', label: 'Correo electrónico'
			}],
			rfc: [{
			  rule: 'empty', params: [/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/gi], label: 'RFC'
			}],
			regimen: [{
				rule: 'empty', label: 'Regimen',
			}],
			use_cfdi: [{
				rule: 'empty', label: 'CFDI',
			}],
			street: [{
				rule: 'empty', label: 'Calle',
			}],    
			neighborhood: [{
				rule: 'minLength', params: [3], label: 'Colonia'
			}],
			city: [{
				rule: 'minLength', params: [3], label: 'Ciudad'
			}],
			state: [{
				rule: 'minLength', params: [3], label: 'Estado'
			}],
			zipcode: [{
				rule: 'empty', label: 'Codigo postal'
			}],
			exterior_number: [{
				rule: 'empty', label: 'Número exterior'
			}],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		var r = { ...rfcForm };
		r.location = location;
		API.createRfc(r, props.deleted).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Dirección fiscal creada!')
			var f = { ...rfcForm };
			f.tax_id = res.data.tax_id;
			setRfcForm(initateForm);
			if(props.onCreate) props.onCreate(f);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando la dirección fiscal (R-CRE-1)']);
		}).finally(()=>{
			setLoading(false);
		})  
	};
	
	var onEditChange = bindFormChange(rfcForm, setRfcForm);
	var onEditLocation = bindFormChange(location, setLocation);

	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>{props.title || 'Crear Datos Fiscales' }</Modal.Header>
		<Modal.Content>
			<Field amount={2}>
				<Input label='Razón social' value={rfcForm.legal_name} onChange={onEditChange('legal_name')}/>
				<Input label='Correo electrónico' value={rfcForm.email} onChange={onEditChange('email')}/>
			</Field>
			<Input label='RFC' value={rfcForm.rfc} onChange={onEditChange('rfc')}/>
			<div style={{padding: 5, fontWeight: 'bold' }}>Regímen</div>
			<Dropdown
				fluid
				search
				selection
				placeholder='Regímen'
				options={regimenList.map(r => ({
					key: r.value,
					text: r.text,
					value: r.value
				}))}
				style={{marginBottom: 10}}
				value={rfcForm.regimen}
				onChange={onEditChange('regimen', true)}
			/>
			<div style={{padding: 5, fontWeight: 'bold' }}>CFDI</div>
			<Dropdown
				fluid
				search
				selection
				placeholder='CFDI'
				options={cfdiList.map(c => ({
					key: c.value,
					text: c.text,
					value: c.value
				}))}
				style={{marginBottom: 10}}
				value={rfcForm.use_cfdi}
				onChange={onEditChange('use_cfdi', true)}
			/>
			<Field>
				<Input label='Calle' value={location.street} onChange={onEditLocation('street')}/>
			</Field>
			<Field amount={2}>
				<Input label='Numero exterior' value={location.exterior_number} onChange={onEditLocation('exterior_number')}/>
				<Input label='Numero interior' value={location.interior_number} onChange={onEditLocation('interior_number')}/>
			</Field>
			<Field amount={2}>
				<Input label='Colonia' value={location.neighborhood} onChange={onEditLocation('neighborhood')}/>
				<Input label='Ciudad' value={location.city} onChange={onEditLocation('city')}/>
			</Field>
			<Field amount={2}>
				<Input label='Estado' value={location.state} onChange={onEditLocation('state')}/>
				<Input label='Código postal' value={location.zipcode} onChange={onEditLocation('zipcode')}/>
			</Field>
         <Message type="error" list={errorPrompts} />
		</Modal.Content>
		<ModalActions>
			<Button text='Cancelar' color='red' basic style={{ minWidth: 20 }} onClick={() => props.onClose()} />
			<Button text='Aceptar' color='black' onClick={create}/>
		</ModalActions>
	</Modal>

}

export default TaxModal;