import React, { useEffect, useState } from 'react';
import { Order } from '../Classes';
import { Groupper, Input, Header, Pagination } from 'react-frontier';
import { Items } from '../components';
import { addCommas, cropString } from '../Util';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { useTitle } from '../Hooks';
import classNames from 'classnames';
import API from '../API';
import moment from 'moment';

import '../style/orders.scss'

const PAGE_SIZE = 20;

var OnlineOrders = ()=>{
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [orders, setOrders] = useState<Order[]>(null);
	var [searchOrders, setSearchOrders] = useState<Order[]>(null);
	var [page, setPage] = useState<number>(0);
	var [searchTimeout, setSearchTimeout] = useState<any>(null);
	var [loading, setLoading] = useState<boolean>(false);
	var [search, setSearch] = useState<string>('');
	
	useEffect(() => {
		if(!orders) get();
		setTitle('Ordenes Online')
	}, []);
	
	var get = (get_page?: number) => {
		var offset = (get_page > 0) ? Math.max(0, (get_page || page) * PAGE_SIZE) : 0;
		
		API.getOrders(true, false, PAGE_SIZE, offset).then(res => {
			if (res.error) return setLoadError(res.message);
			return setOrders(res.data.web);
		}).catch(err => {
			return setLoadError('Hubo un error al obtener las ordenes. (OOG-1)');
		}).finally(()=>{
			setLoading(false);
		})
	};
	
	var OrderItem = (order: Order)=>(
		<div className={classNames('container', {
			shipped: order.shipped
		})}>
			<div className='name'>
				<div>{order.owner_name}</div>
				{!!order.shipping_date && (
					<div className="date">
						<Icon name={classNames({
							'shopping basket': order.shipping_method!==1,
							'truck': order.shipping_method===1,
						}) as SemanticICONS} /> 
						{moment.unix(order.shipping_date).format('DD/MMM/YY')}
					</div>
				)}
				<div className="date">
					<i className="pencil icon"></i>
					{moment.unix(order.date_created).format('DD/MMM/YY')}
				</div>
			</div>
			<div className='location'>
				<div>{cropString(order.city || 'Monterrey', 30, true)}</div>
				<div className='price'>${addCommas(order.total)}</div>
			</div>
			<Icon name={classNames({
				'shopping basket': order.shipping_method!==1,
				'truck': order.shipping_method===1,
			}) as SemanticICONS} color='black' />
		</div>
	)
		
	var searchOrder = (val: string)=>{
		setLoading(true);
		API.searchOrder(val, null).then(res=>{
			if (res.error) return setLoadError(res.message);
			setOrders(res.data);
			return setLoading(false);
		}).catch(err=>{
			setLoading(false);
			return setLoadError('Hubo un error al buscar la orden . (SOS-1)');
		})
	}
	
	var onPageChange = async(page: number)=>{
		setLoading(true)
		setPage(page);
		get(page);
	}
	
	var onInputSearch = (val: string)=>{
		setSearch(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3) return setSearchOrders(null);
		
		setSearchTimeout(setTimeout(()=>{
			searchOrder(val)
		}, 400));
	}
	
	if(loadError){
		return <Header text='Error' subtext={loadError} />
	};
	
	if(orders===null){
		return <Header loading text='Cargando datos' />
	};

	var shown_orders = searchOrders || orders;
	
	var pending_orders = shown_orders.filter(a=>!!!a.shipped);
	var shipped_orders = shown_orders.filter(a=>!!a.shipped);
		
	return <div className='co orders'>
		<Groupper title='Ordenes ONLINE' style={{ maxWidth: 600 }}>
			<Input placeholder='Buscar en ordenes' value={search} onChange={onInputSearch} />
			{loading ? (
				<Header loading text='Cargando datos' />
			) : <>
				{pending_orders.length>0 && <>
					<div className="section centered head small">Pendientes</div>
					<Items
						data={pending_orders}
						selectable={true}
						keyExtractor={(order) => `ORDNSH-${order.order_id}`}
						linkExtractor={order => `/orders/${order.order_id}`}
						render={OrderItem}
					/>
				</>}
				{shipped_orders.length>0 && <>
					<div className={"section centered head small"}>Completadas</div>
					<Items
						data={shipped_orders}
						selectable={true}
						keyExtractor={(order) => `ORDSH-${order.order_id}`}
						linkExtractor={order => `/orders/${order.order_id}`}
						render={OrderItem}
					/>
				</>}
				{shown_orders.length==0 && (
					<Header iconName='frown' style={{ marginTop: 30, marginBottom: 30 }} text='No se encontraron ordenes' />
				)}
			</>}
			{((!searchOrders && orders.length >= PAGE_SIZE) || page !== 0) && (
				<div className='actions' style={{ textAlign: 'right' }}>
					<Pagination page={page} onPageChange={onPageChange} hasNext={orders.length>=PAGE_SIZE} />
				</div>
			)}
		</Groupper>
	</div>
}
							
export default OnlineOrders;