import React, { useEffect, useState } from 'react';
import { Button, Input, Header, Pagination, Table, Image } from 'react-frontier';
import { addCommas } from '../Util';
import { Link } from "react-router-dom";
import { useTitle } from '../Hooks';
import { WebProduct } from '@cocoan/components/Classes';
import API from '../API';

import '../style/product_list.scss';
import moment from 'moment';

const PAGE_SIZE = 20;

var Products = ()=>{
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [products, setProducts] = useState<WebProduct[]>(null);
	var [searchProducts, setSearchProducts] = useState<WebProduct[]>(null)
	var [filter, setFilter] = useState<string>('');
	var [page, setPage] = useState<number>(0);
	var [searching, setSearching] = useState<boolean>(false);
	var [searchTimeout, setSearchTimeout] = useState<any>(null);

	useEffect(() => {
		if(!products) get();
		setTitle('Productos');
	}, []);

	var get = (get_page?: number)=>{
		setSearching(true);
		API.getProducts((get_page || page)*PAGE_SIZE, PAGE_SIZE).then(res=>{
			if(res.error) return setLoadError(res.message);
			return setProducts(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener los productos. (GPE-1)'); 
		}).finally(()=>{
			setSearching(false);
		})
	}

	var search = (query: string)=>{
		setSearching(true);
		setSearchProducts(null);
		setPage(0);
		API.searchProducts(query).then(res=>{
			if (res.error) return setLoadError(res.message);
			setSearchProducts(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al buscar el producto. (SPS-1)');
		}).finally(()=>{
			setSearching(false);
		})
	}

	var onPageChange = (page: number)=>{
		setPage(page);
		get(page);
	}

	var onSearchChange = (val: string)=>{
		setFilter(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3){
			return setSearchProducts(null);
		}
		setSearchTimeout(setTimeout(()=>{
			search(val)
		}, 500));
	}


	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(products===null){
		return <Header loading text='Cargando datos' />
	}

	var shown_products = searchProducts || products;

	return <Table
		className='products-list'
		style={{ maxWidth: 800, margin: 'auto' }}
		striped
		selectable
		title={'Productos'}
		headers={['', 'Nombre', 'PDV', 'Online']}
		collapsingIndexes={[0, 2, 3]}
		footer={shown_products.length >= PAGE_SIZE || page !== 0 ? (
			<Table.Row>
				<Table.Cell colSpan={5}>
					<div style={{ textAlign: 'right' }}>
						<Pagination page={page} onPageChange={onPageChange} />
					</div>
				</Table.Cell>
			</Table.Row>
		) : null}
		button={(
			<div className="right" style={{ display: 'flex', alignItems: 'center' }}>
				<Input placeholder='Buscar productos' value={filter} onChange={onSearchChange} style={{ margin: 0, marginRight: 10 }} />	
				<Button size='tiny' color='black' iconName='plus-circle' text='Agregar' as={Link} to='/products/create' style={{ padding: '8px 15px' }} />
			</div>
		)}
	>
		{searching ? (
			<Table.Row>
				<Table.Cell className='empty' colSpan={5}><Header loading text='Cargando datos' /></Table.Cell>
			</Table.Row>
		) :
			shown_products.length !== 0 ? (
				shown_products.map(p => (
					<Table.Row compact key={`tblr-${p.product_id}`} as={Link} to={`/products/${p.product_id}`} collapsingIndexes={[0, 2, 3, 4]}>
						<Table.Cell collapsing className='desktop'>
							<Image src={`${API.CDN_URL}/products/${p.product_id}/0.webp?t=${moment().unix()}`} fallback='/placeholder.webp' />
						</Table.Cell>
						<Table.Cell>
							<div className="id" style={{ color: 'gray', fontSize: 12 }}>ID: {p.product_id}</div>
							<div className="name" style={{ fontWeight: 'bold', fontSize: 16 }}>{p.product_name}</div>
						</Table.Cell>
						<Table.Cell collapsing>${addCommas(p.price_pdv)}</Table.Cell>
						<Table.Cell collapsing>${addCommas(p.price)}</Table.Cell>
					</Table.Row>
				))
			) : (
				<Table.Row>
					<Table.Cell className='empty' colSpan={5}>No se encontraron registros</Table.Cell>
				</Table.Row>
			)
		}
	</Table>
}

export default Products;