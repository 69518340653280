import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link , useNavigate } from "react-router-dom";
import { useTitle } from '../Hooks';
import { EditSitePage, SitePageAsset, SetLoading, SiteAssetType, SiteType } from '../Classes';
import { NotFound } from '../components';
import { Header, Groupper, Input, Button, Message, Field, Table } from 'react-frontier';
import { Dropdown, ModalActions, Modal, Checkbox, Icon } from 'semantic-ui-react';
import { bindClick, bindFormChange } from '../Util';
import MDEditor, { commands } from '@uiw/react-md-editor';
import API from '../API';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import moment from 'moment';
import classNames from 'classnames';

const initialAssetForm : SitePageAsset = {
	asset_name: null,
	asset_type: null,
	asset_description: null,
	deleted: false
};

var PageEdit = ()=>{
	var { page_id } = useParams();
	var { setTitle } = useTitle();
	var navigate = useNavigate();
	const IS_CREATE = page_id==='create';
	var [page, setPage] = useState<EditSitePage>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [errorModalPrompts, setErrorModalPrompts] = useState<string[]>([]);
	var [deleteModal, setDeleteModal] = useState<boolean>(false);
	var [asset, setAsset] = useState<SitePageAsset>(null);
	var [createAssetModal, setCreateAssetModal] = useState<boolean>(null);
	var [addingBanner, setAddingBanner] = useState<boolean>(false);
	var [viewingBannerImage, setViewingBannerImage] = useState<number>(null);
	var [deleteBannerModal, setDeleteBannerModal] = useState<boolean>(false);
	var [addingAssetImage, setAddingAssetImage] = useState<boolean>(false);
	var [viewingAssetImage, setViewingAssetImage] = useState<number>(null);
	var [deleteAssetImageModal, setDeleteAssetImageModal] = useState<boolean>(false);
	var [deleteAssetModal, setDeleteAssetModal] = useState<boolean>(false);
	var pdfInputRef = useRef<HTMLInputElement>(null);
	var bannerInputRef = useRef<HTMLInputElement>(null);
	var assetImageInputRef = useRef<HTMLInputElement>(null);


	useEffect(() => {
		get()
	}, []);

	var get = async()=>{
		try {
			if(!IS_CREATE){
				var res = await API.getSitePage(parseInt(page_id));

				if (res.error) return setLoadError(res.message);
				setTitle(res.data.page_title);
				if(res.data.button_text) res.data.button = true;
				setPage(res.data);
			}else{
				return setPage({
					page_url: null,
					page_title: null,
					page_subtitle: null,
					page_contents: null,
					page_type: null,
					deleted: false,
					button_text: null,
					button_url: null,
					button: false
				});
			}
		} catch (error) {
			setLoadError('Hubo un error al obtener la pagina. (GCE-1)');
		}
	};

	var create = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(page, {
			page_url: [{
				rule: 'minLength', params: [3], label: 'URL'
			}],
			page_title: [{
				rule: 'minLength', params: [3], label: 'Título'
			}],
			page_type: [{
				rule: 'number', label: 'Tipo'
			}],
			...(page.page_subtitle ? {
				page_subtitle: [{
					rule: 'minLength', params: [3], label: 'Subtítulo'
				}]
   		} : {}),
			...(page.button ? {
				button_text: [{
					rule: 'minLength', params: [3], label: 'Texto de Botón'
				}],
				button_url: [{
					rule: 'minLength', params: [3], label: 'URL de Botón'
				}]
   		} : {})
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createSitePage(page.page_url, page.page_title, page.page_subtitle, page.page_contents, page.page_type, page.button_text, page.button_url).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);

			Toast.success('Pagina creada!');
			navigate(`/sites/pages/${res.data.site_page[0]}`);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando la pagina (SP-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	var openCreateAssetModal = ()=>{
		setAsset(initialAssetForm);
		return setCreateAssetModal(true);
	}

	var openAssetEdit = (asset: SitePageAsset)=>{
		setAsset(asset);
		return setCreateAssetModal(true);
	}

	var createAsset = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(asset, {
			asset_name: [{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			asset_type: [{
				rule: 'number', label: 'Tipo'
			}],
			...(asset.asset_description ? {
				asset_description: [{
					rule: 'minLength', params: [3], label: 'Descripción'
				}]
   		} : {})
		});
		setErrorModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);

		API.createSiteAsset(page.page_id, asset.asset_type, asset.asset_name, asset.asset_description).then(res=>{
			if(res.error) return setErrorModalPrompts([res.message]);

			var p = {...page};
			asset.page_id = page.page_id;
			asset.asset_id = res.data.asset_id;
			p.assets.push(asset);
			setPage(p);

			Toast.success('Recurso creado!');
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando el recurso. (SP-AST-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var addBanner = ()=>{
		bannerInputRef.current?.click();
	}

	var addAssetImage = ()=>{
		assetImageInputRef.current?.click();
	}

	var viewBanner = (image: number)=>{
		return ()=>{
			setViewingBannerImage(image)
		}
	}

	var viewAssetImage = (image: number)=>{
		return ()=>{
			setViewingAssetImage(image)
		}
	}

	var handleBannerImage = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(!ev.target.files || ev.target.files.length==0) return;
		if(ev.target.files.length>1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if(image.size>(2.5*1024*1024)) return Toast.error('La imagen no puede pesar mas de 2.5MB.');

		setAddingBanner(true);
		API.addPageBanner(parseInt(page_id), image).then(res=>{
			if(res.error) return Toast.error(res.message);
			
			var p = {...page};
			if(!p.banner) p.banner = null;
			if(!p.banner){
				p.banner = res.data.image_url;
			}
			setPage(p);

			Toast.success('Se ha agregado la imagen del usuario');
		}).catch(err=>{
			return Toast.error('Hubo un error subiendo la imagen del usuario. (LCL-1)');
		}).finally(()=>{
			setAddingBanner(false);
		});
	}

	var handleAssetImage = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(!ev.target.files || ev.target.files.length==0) return;
		if(ev.target.files.length>1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if(image.size>(2.5*1024*1024)) return Toast.error('La imagen no puede pesar mas de 2.5MB.');

		setAddingAssetImage(true);
		API.addAssetImage(asset.asset_id, image).then(res=>{
			if(res.error) return Toast.error(res.message);
			
			var p = {...page};
			var i = p.assets.findIndex(a => a.asset_id === asset.asset_id);
			asset.image = res.data.image_url;
			p.assets[i] = asset;
			setPage(p);

			Toast.success('Se ha agregado la imagen del recurso.');
		}).catch(err=>{
			return Toast.error('Hubo un error subiendo la imagen del recurso. (LCL-1)');
		}).finally(()=>{
			setAddingAssetImage(false);
		});
	}

	var deleteBanner = (image: number)=>{
		return (setLoading: SetLoading)=>{
			setLoading(true);
			API.deletePageBanner(page.page_id).then(res=>{
				if(res.error) return Toast.error(res.message);

				var p = {...page};
				p.banner = res.data.image;
				setPage(p);
				Toast.success('Se ha eliminado la imagen de banner.');
				setDeleteBannerModal(false);
				setViewingBannerImage(null);
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado eliminando la imagen del banner. (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var deleteAssetImage = (image: number)=>{
		return (setLoading: SetLoading)=>{
			setLoading(true);
			API.deleteAssetImage(asset.asset_id).then(res=>{
				if(res.error) return Toast.error(res.message);
				Toast.success('Se ha eliminado la imagen del recurso.');

				asset.image = null;
				var p = {...page};
				var i = p.assets.findIndex(a => a.asset_id === asset.asset_id);
				p.assets[i] = asset;
				setPage(p);

				setDeleteAssetImageModal(false);
				setViewingAssetImage(null);
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado eliminando la imagen del recurso. (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var deletePdf = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.deleteAssetPdf(asset.asset_id).then(res=>{
			if(res.error) return setErrorModalPrompts([res.message]);
			Toast.success('Se ha eliminado el pdf del recurso.');

			asset.pdf = null;
			var p = {...page};
			var i = p.assets.findIndex(a => a.asset_id === asset.asset_id);
			p.assets[i] = asset;
			setPage(p);

		}).catch(err=>{
			return Toast.error('Hubo un error inesperado eliminando el PDF del recurso. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var updateAsset = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(asset, {
			asset_name: [{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			asset_type: [{
				rule: 'number', label: 'Tipo'
			}],
			...(asset.asset_description ? {
				asset_description: [{
					rule: 'minLength', params: [3], label: 'Descripción'
				}]
   		} : {})
		});
		setErrorModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);

		API.updateSiteAsset(asset.asset_id, asset.asset_type, asset.asset_name, asset.asset_description).then(res=>{
			if(res.error) return setErrorModalPrompts([res.message]);
			
			var p = {...page};
			var i = p.assets.findIndex(a => a.asset_id === asset.asset_id);
			p.assets[i] = asset;
			setPage(p);

			Toast.success('Recurso actualizado!');
			setCreateAssetModal(false);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado actualizando el recurso. (SP-AST-UPD-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var handleFileChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files || ev.target.files.length === 0) return;
		if (ev.target.files.length > 1) return Toast.error("Please select only 1 file.");
		var file = ev.target.files[0];

		API.addPDF(asset.asset_id, file).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se ha agregado el pdf al recurso.');
		}).catch(err => {
			return Toast.error('Hubo un error agregando el pdf al recurso. (LCL-1)');
		});
	};

	var update = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(page, {
			page_url: [{
				rule: 'minLength', params: [3], label: 'URL'
			}],
			page_title: [{
				rule: 'minLength', params: [3], label: 'Título'
			}],
			page_type: [{
				rule: 'number', label: 'Tipo'
			}],
			...(page.page_subtitle ? {
				page_subtitle: [{
					rule: 'minLength', params: [3], label: 'Subtítulo'
				}]
   		} : {}),
			...(page.button ? {
				button_text: [{
					rule: 'minLength', params: [3], label: 'Texto de Botón'
				}],
				button_url: [{
					rule: 'minLength', params: [3], label: 'URL de Botón'
				}]
   		} : {})
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		if(!page.button){
			page.button_text= null;
			page.button_url = null;
		}
		setLoading(true);

		if(!IS_CREATE){
			API.updateSitePage(page.page_id, page.page_url, page.page_title,page.page_subtitle, page.page_contents, page.page_type, page.button_text, page.button_url).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Pagina actualizada!');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado actualizando la pagina. (UPD-1)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	};

	var deletePage = (setLoading: (l: boolean)=>void)=>{;
		setLoading(true);
		API.deleteSitePage(parseInt(page_id), !page.deleted).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Pagina acutalizada!');
			var p = {...page};
			p.deleted = !p.deleted;
			if(!p.deleted){
				p.date_deleted = moment().unix();
			}
			setPage(p);
			return setDeleteModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado eliminando la pagina. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var openDeleteAssetModal = (asset: SitePageAsset)=>{
		setAsset(asset);
		return setDeleteAssetModal(true);
	}

	var deleteAsset = (setLoading: (l: boolean)=>void)=>{;
		setLoading(true);
		API.deleteSiteAsset(asset.asset_id, true).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Recurso eliminado!');

			var p = {...page};
			p.assets = p.assets.filter(a => a.asset_id !== asset.asset_id);
			setPage(p);
			return setDeleteAssetModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado eliminando el recurso. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onEditChange = bindFormChange(page, setPage);
	var onEditAsset = bindFormChange(asset, setAsset);

	if(!IS_CREATE && Number.isNaN(parseInt(page_id))){
		return <NotFound />
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if (!page) {
		return <Header loading text='Cargando datos' />;
	}
	
	var now = moment().unix();

	return <div>
		<Groupper title={!IS_CREATE ? 'Editar Pagina' : 'Crear Pagina'} width={500} titleCentered actions={
			<>
				{!IS_CREATE && !page.deleted ? <Button text="Eliminar" basic color='red' style={{ marginRight: 5 }} onClick={() => setDeleteModal(true)} /> : !IS_CREATE && page.deleted ? <Button text="Restaurar" color='green' style={{ marginRight: 5 }} onClick={() => setDeleteModal(true)} /> : null}
				{!IS_CREATE ? <Button text="Guardar" color="black" onClick={update}/> : <Button text="Crear" color="black" onClick={create}/>}
			</>
		}>
			{page_id !== 'create' && <>
				<div className="section head" style={{ marginTop: -21 }}>Banner</div>
				<input type="file" style={{ display: 'none' }} ref={bannerInputRef} accept="image/*" onChange={handleBannerImage}/>
				<div className="co single product landscape images" style={{ marginBottom: 10 }}>
					{!!page.banner ? (
						<img src={`${page.banner}?t=${now}`} className="image" onClick={viewBanner(-1)} />
					) : (
						<div className={classNames('image', {
							add: !addingBanner,
							loading: addingBanner,
						})} onClick={addBanner}>
							{addingBanner ? (
								<div className="fr loading inline"></div>
							) : (
								<Icon name="plus" />
							)}
						</div>
					)}
				</div>
			</>}
			{!IS_CREATE && !!page.page_id && (
				<div style={{ textAlign: 'center' }}>
					<Button text='Ver página' style={{ width: 200 }} color='black' as={Link} to={`${API.PUBLIC_URL}/@${page.page_url}`} target='_blank' />
				</div>
			)}
			<Input label='URL' value={page.page_url} onChange={onEditChange('page_url')} />
			<Input label='Título' value={page.page_title} onChange={onEditChange('page_title')} />
			<Input label='Subtítulo ' value={page.page_subtitle} onChange={onEditChange('page_subtitle')} />
			<Field label='Opciones' style={{ marginTop: 15}}>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Agregar Botón' checked={page.button} onChange={() => onEditChange('button')(!page.button)}/>
			</Field>
			{page.button && <>
				<Input label='Texto Botón' value={page.button_text} onChange={onEditChange('button_text')} />
				<Input label='URL Botón' value={page.button_url} onChange={onEditChange('button_url')} />
			</>}
			<div style={{padding: 5, fontWeight: 'bold' }}>Tipo</div>
			<Dropdown
				placeholder='Tipo'
				fluid
				selection
				disabled={!IS_CREATE}
				options={
					[
						{text: 'PDF' , value: 1},
						{text: 'Galeria de imagenes' , value: 2},
						{text: 'Texto' , value: 3}
					]
				}
				style={{marginBottom: 10}}
				value={page.page_type}
				onChange={onEditChange('page_type', true)}
			/>
			<Message type="error" list={errorPrompts} style={{ marginTop: 15 }}/>
		</Groupper>
		{page.page_type === SiteType.TEXT && (
			<Groupper title='Contenido' width={800} fitted style={{ marginTop: 15 }}>
				<MDEditor 
					preview='edit'
					style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}
					value={page.page_contents} 
					onChange={onEditChange('page_contents')} 
					height={600}
					data-color-mode='light'
					extraCommands={[
						{
							...commands.codeEdit,
							icon: <Icon name='pencil' style={{ margin: 0, fontSize: 18 }} />
						},
						{
							...commands.codePreview,
							icon: <Icon name='eye' style={{ margin: 0, fontSize: 18 }} />
						},
					]}
					commands={[
						commands.bold,
						commands.italic,
						commands.strikethrough,
						commands.divider,
						commands.hr,
						commands.quote,
						commands.code,
						commands.divider,
						commands.unorderedListCommand,
						commands.orderedListCommand,
					]}
				/>	
			</Groupper>
		)}
		{!IS_CREATE && page.page_type!==SiteType.TEXT && (
			<Table
				title='Recursos'
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
				headers={['ID', 'Nombre', 'Tipo', '']}
				emptyText={page && (!page.assets || page.assets.length === 0) && 'No se encontraron registros.'}
				collapsingIndexes={[0, 2, 3]}
				button={
					<Button size='tiny' color='black' iconName='plus-circle' text='Agregar' onClick={openCreateAssetModal}/>
				}
			>
				{page.assets && page.assets.map(a=>(
					<Table.Row
						collapsingIndexes={[0, 2, 3]}
						key={a.asset_id}
						onClick={() => openAssetEdit(a)}
						data={[
							a.asset_id,
							a.asset_name,
							a.asset_type === SiteAssetType.PDF ? 'PDF' : 'Imagen',
							<Dropdown icon={(
								<div className="fr label" style={{ padding: '3px 10px' }}>
									<Icon name='ellipsis horizontal' size='small' style={{ margin: 0 }} />
								</div>
							)} className='icon'>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => openAssetEdit(a)}>
										<Icon name="pencil" style={{ marginRight: 3 }} /> Editar 
									</Dropdown.Item>
									<Dropdown.Item onClick={() => openDeleteAssetModal(a)}>
										<Icon name="close" style={{ marginRight: 3 }} /> Eliminar 
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						]}
					/>
				))}
			</Table>
		)}
		<Modal open={deleteModal} onClose={bindClick(setDeleteModal)} size='mini'>
			<Modal.Header>Confirmación</Modal.Header>
			<Modal.Content>
				<Header text={`¿ ${!page.deleted ? 'Eliminar' : 'Restaurar'} pagina?`} />
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={ [['Título', page.page_title]] }
				/>
			</Modal.Content>
			<ModalActions>
				<Button onClose={bindClick(setDeleteModal)} text='Cancelar' basic/>
				<Button onClick={deletePage} color={page.deleted ? 'green' : 'red'} text={page.deleted ? 'Restaurar' : 'Eliminar'} />
			</ModalActions>
		</Modal>
		<Modal open={createAssetModal} onClose={bindClick(setCreateAssetModal)} size='small'>
			<Modal.Header>{asset && asset.asset_id ? 'Editar Recurso' : 'Crear Recurso'}</Modal.Header>
			{asset && <Modal.Content>
				<Input label='Nombre' value={asset.asset_name} onChange={onEditAsset('asset_name')} />
				<Input label='Descripción' value={asset.asset_description} onChange={onEditAsset('asset_description')} />
				<div style={{padding: 5, fontWeight: 'bold' }}>Tipo</div>
				<Dropdown
					placeholder='Tipo'
					fluid
					selection
					options={[
						{ text: 'PDF', value: 1 },
						{ text: 'Imagen', value: 2 },
					]}
					style={{marginBottom: 10}}
					value={asset.asset_type}
					onChange={onEditAsset('asset_type', true)}
				/>
				{asset.asset_id && asset.asset_type === 1 && !asset.pdf ? (
					<div style={{ textAlign: 'center', marginTop: 15 }}>
						<input type="file" style={{ margin: 'auto' }} ref={pdfInputRef} accept=".pdf"  onChange={handleFileChange} />
					</div>
				) : asset.asset_id && asset.asset_type === 1 && asset.pdf ? (
					<div style={{ padding: 15, textAlign: 'center' }}>
						<Button text='Ver PDF' color='black' style={{ marginRight: 15 }} as={Link} to={asset.pdf} target='_blank' />
						<Button text='Eliminar PDF' color='red' onClick={deletePdf}/>
					</div>
				) : asset.asset_id ? <>
					<input type="file" style={{ display: 'none' }} ref={assetImageInputRef} accept="image/*" onChange={handleAssetImage}/>
					<div className="co single product portrait images">
						{!!asset.image ? (
							<img src={asset.image} className="image" onClick={viewAssetImage(-1)} />
						) : (
							<div className={classNames('image', {
								add: !addingAssetImage,
								loading: addingAssetImage,
							})} onClick={addAssetImage}>
								{addingAssetImage ? (
									<div className="fr loading inline"></div>
								) : (
									<Icon name="plus" />
								)}
							</div>
						)}
					</div>
				</> : null}
				<Message type="error" list={errorModalPrompts} style={{ marginTop: 15 }}/>
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setCreateAssetModal)} text='Cancelar' basic/>
				{asset && asset.asset_id ? (
					<Button text='Guardar' color='black' onClick={updateAsset}/>
				) : (
					<Button text='Crear' color='black' onClick={createAsset} />
				)}
			</ModalActions>
		</Modal>
		<Modal open={viewingBannerImage!==null && !deleteBannerModal} size='mini' onClose={bindClick(setViewingBannerImage)}>
			<Modal.Header>Imagen</Modal.Header>
			{viewingBannerImage!==null && <Modal.Content>
				<img src={`${page.banner}?t=${now}`} style={{ width: '100%', maxWidth: 400, display: 'block', margin: 'auto' }} />
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setDeleteBannerModal, true)} color='red' basic text='Eliminar' />
				<Button onClick={bindClick(setViewingBannerImage)} text='Cerrar'/>
			</Modal.Actions>
		</Modal>
		<Modal open={viewingBannerImage!==null && deleteBannerModal} size='mini' onClose={bindClick(setDeleteBannerModal)}>
			<Modal.Header>Eliminar imagen</Modal.Header>
			{viewingBannerImage!==null && deleteBannerModal && <Modal.Content>
				<div className="co product portrait single images" style={{ justifyContent: 'center' }}>
					<img src={page.banner} className='image' />
				</div>
				<Header text='¿Eliminar imagen?' style={{ marginTop: 15, marginBottom: 0 }}/>
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setDeleteBannerModal)} text='Cancelar' basic />
				<Button onClick={deleteBanner(viewingBannerImage)} color='red' text='Eliminar' />
			</ModalActions>
		</Modal>

		<Modal open={viewingAssetImage!==null && !deleteAssetImageModal} size='mini' onClose={bindClick(setViewingAssetImage)}>
			<Modal.Header>Imagen</Modal.Header>
			{viewingAssetImage!==null && <Modal.Content>
				<img src={asset.image} style={{ width: '100%', maxWidth: 400, display: 'block', margin: 'auto' }} />
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setDeleteAssetImageModal, true)} color='red' text='Eliminar' />
				<Button onClick={bindClick(setViewingAssetImage)} text='Cerrar'/>
			</Modal.Actions>
		</Modal>
		<Modal open={viewingAssetImage!==null && deleteAssetImageModal} size='mini' onClose={bindClick(setDeleteAssetImageModal)}>
			<Modal.Header>Eliminar imagen</Modal.Header>
			{viewingBannerImage!==null && deleteBannerModal && <Modal.Content>
				<div className="co product portrait single images" style={{ justifyContent: 'center' }}>
					<img src={`${page.banner}?t=${now}`} className='image' />
				</div>
				<Header text='¿Eliminar imagen?' style={{ marginTop: 15, marginBottom: 0 }}/>
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setDeleteAssetImageModal)} text='Cancelar' basic />
				<Button onClick={deleteAssetImage(viewingAssetImage)} color='red' text='Eliminar' />
			</ModalActions>
		</Modal>
		<Modal open={deleteAssetModal} onClose={bindClick(setDeleteAssetModal)} size='mini'>
			<Modal.Header>Confirmación</Modal.Header>
			{asset && <Modal.Content>
				<Header text='Eliminar recurso.' />
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={[['Nombre', asset.asset_name]]}
				/>
			</Modal.Content>}
			<ModalActions>
				<Button onClose={bindClick(setDeleteAssetModal)} text='Cancelar' basic/>
				<Button onClick={deleteAsset} color='red' text='Eliminar' />
			</ModalActions>
		</Modal>
	</div>
}

export default PageEdit;