import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { CodeCategory } from '../Classes';
import { Button, Input, Message, Header, Groupper, Field, Table } from 'react-frontier';
import { Modal, Icon, Checkbox } from 'semantic-ui-react';
import { bindClick, bindSimpleFormChange } from '../Util';
import { useTitle } from '../Hooks';
import Toast from 'react-hot-toast';
import Validator from '../Validator';
import API from '../API';

var Zipcode = ()=>{
	var { category_id } = useParams();
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [category, setCategory] = useState<CodeCategory>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [createModal, setCreateModal] = useState<boolean>(false);
	var [newCode, setNewCode] = useState<string>(null);
	var [filter, setFilter] = useState<string>('');
	var [sendingCode, setSendingCode] = useState<string>(null);

	useEffect(() => {
		get();
	}, []);

	var get = async()=>{
		API.getCodeCategory(parseInt(category_id)).then(res=>{
			if (res.error) return setLoadError(res.message);
			setTitle(`Postal ${res.data.name}`);
			return setCategory(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error al obtener la categoria. (GC-1)');
		});
	};

	var update = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(category, {
			name:[{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			cost:[{
				rule: 'number', label: 'Nombre'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editCodeCategory(category.category_id, category.name, category.cost, category.enabled, category.local).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Categoria actualizada!');
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado actualizando la categoria (CC-UPD-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	};

	var deleteCode = async(zipcode: string)=>{
		setSendingCode(zipcode);
		API.editCategoryCodes(parseInt(category_id), zipcode, false).then(res=>{
			var cde = {...category};
			if(res.error) return Toast.error(res.message);
			cde.zipcodes = cde.zipcodes.filter(p=>p.zipcode!==zipcode);
			cde.zipcodes = cde.zipcodes.sort((a,b)=> {
				if (a.zipcode < b.zipcode) return -1;
				if (a.zipcode > b.zipcode) return 1;
				return 0;
			});
			setCategory(cde);
			return Toast.success('Código eliminado!');
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado eliminando el código postal (CC-DTE-1)');
		}).finally(()=>{
			setSendingCode(null);
		});
	}

	var addCode = async(setLoading: (l: boolean)=>void)=>{
		if(newCode == null || typeof newCode !== 'string') return setErrorPrompts(['El código postal es inválido.']);
		setLoading(true);
		API.editCategoryCodes(parseInt(category_id), newCode, true).then(res=>{
			var cde = {...category};
			cde.zipcodes.push({
				category_id: parseInt(category_id),
				zipcode: newCode
			})
			if(res.error) return setErrorPrompts([res.message]);
			cde.zipcodes = cde.zipcodes.sort((a,b)=> {
				if (a.zipcode < b.zipcode) return -1;
				if (a.zipcode > b.zipcode) return 1;
				return 0;
			});
			setCategory(cde);
			setCreateModal(false);
			return Toast.success('Código agregado!');
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado agregando el código postal (CC-ADD-1)');
		}).finally(()=>{
			setNewCode(null);
			setLoading(false);
		});
	}

	var onEditChange = bindSimpleFormChange(category, setCategory);

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!category){
		return <Header loading text='Cargando datos...' />
	}

	var search = category.zipcodes.filter(zipcode => {
		return String(zipcode.zipcode).includes(filter.toString());
	});

	return <div>
		<Groupper title={`Editar Codigo`} width={500} titleCentered actions={(
			<Button text="Guardar" color="black" size='small' onClick={update}/>
		)}>
			<Field amount={2}>
				<Input label='Nombre' value={category.name} onChange={onEditChange('name')}/>
				<Input label='Costo' value={category.cost} onChange={onEditChange('cost')}/>
			</Field>
			<Checkbox toggle style={{display:'flex', marginBottom: 10, marginTop: 15}} label='Habilitado' checked={category.enabled} onChange={() => onEditChange('enabled')(!category.enabled)}/>
			<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Local' checked={category.local} onChange={() => onEditChange('local')(!category.local)}/>
			<Table
				striped
				title='Códigos postales'
				button={<Button size='tiny' text='Agregar' color='black' iconName='plus-circle' onClick={() => setCreateModal(true)}/>}
				headers={['CÓDIGO POSTAL', <Icon name='wrench'/>]}
				className='top'
				style={{ marginTop: 25 }}
				centeredIndexes={[1]}
			>
				{search.length === 0 ? (
					<Table.Cell row className='empty' colSpan={5}>No se encontraron registros...</Table.Cell>
				) : (
					search.map(z => (
						<Table.Row
							key={z.zipcode}
							collapsingIndexes={[1]}
							data={[
								z.zipcode,
								sendingCode!==z.zipcode ? (
									<Button size='tiny' style={{ minWidth: 30 }} icon iconName='trash' onClick={() => deleteCode(z.zipcode)}/>
								) : (
									<div className="fr loading inline" style={{ width: 30 }}></div>
								)
							]}
						/>
					))
				)}
			</Table>
			<Message type="error" list={errorPrompts} />
		</Groupper>
		<Modal open={createModal} size='tiny' onClose={bindClick(setCreateModal)}>
			<Modal.Header>Agregar código postal</Modal.Header>
			<Modal.Content>
				<Input label='Código postal' value={newCode} onChange={setNewCode} />
				<Message type="error" list={errorPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setCreateModal)} basic />
				<Button text='Agregar' color='black' onClick={addCode}/>
			</Modal.Actions>
		</Modal>
	</div>
}

export default Zipcode;