import React, { useEffect, useState } from 'react';
import API from '../API';
import { SitePage, SiteType } from '../Classes';
import { useTitle } from '../Hooks';
import { Table, Header, Button } from 'react-frontier';
import { Link } from "react-router-dom";

var SitePages = ()=>{
	var { setTitle } = useTitle();
	var [pages, setPages] = useState<SitePage[]>(null);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(()=>{
		setTitle('Paginas');
		get();
	}, []);

	var get = async()=>{
		API.getSitePages().then(res=>{
			if(res.error) return setLoadError(res.message);
			return setPages(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener las paginas. (GPE-1)'); 
		})
	};

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!pages){
		return <Header loading text='Cargando datos' />
	}

	var getPageType = (page_type: SiteType)=>{
		switch(page_type){
			case SiteType.PDF: return 'PDF';
			case SiteType.IMAGE_GALLERY: return 'Galería';
			case SiteType.TEXT: return 'Texto';
			default: return '???';
		}
	}

	return <div>
		<Table
			style={{ maxWidth: 600, margin: 'auto' }}
			title='Paginas'
			striped
			selectable
			headers={['ID', 'URL', 'Título', 'Tipo']}
			collapsingIndexes={[3]}
			emptyText={pages.length === 0 && 'No se encontraron registros.'}
			button={ <Button size='tiny' color='black' iconName='plus-circle' text='Agregar' as={Link} to={'/sites/pages/create'}/> }
		>
			{pages.map(p=><Table.Row
				compact
				selectable
				collapsingIndexes={[0, 1, 3]}
				as={Link} 
				to={`/sites/pages/${p.page_id}`}
				data={[
					p.page_id,
					`@${p.page_url.toLowerCase()}`,
					p.page_title,
					getPageType(p.page_type)
				]}
			/>)}
		</Table>
	</div>
}

export default SitePages;