import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderTag, SetLoading } from '../Classes';
import { Button, Field, Groupper, Header, Input, Label, Message } from 'react-frontier';
import { bindFormChange, getTextColor } from '../Util';
import { HexColorPicker } from 'react-colorful';
import Toast from 'react-hot-toast';
import API from '../API';
import Validator from '../Validator';

interface TagEditProps{
	
}

var TagEdit = (props: TagEditProps)=>{
	var params = useParams<{ tag: string }>();
	var navigate = useNavigate();
	const IS_CREATE = params.tag==='create';
	var [tag, setTag] = useState<OrderTag>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(()=>{
		if(!IS_CREATE){
			setTag(null);
			API.getTag(parseInt(params.tag)).then(res=>{
				if(res.error) return setLoadError(res.message);
				setTag(res.data);
			}).catch(err=>{
				setLoadError('Hubo un error cargando el tag (LCL-TGGT-1)');
			});
		}else if(!tag){
			setTag({
				tag_id: null,
				tag_color: '',
				tag_name: '',
			});
		}
	}, [params.tag]);
	
	var valid_hex = useMemo(()=>{
		if(!tag) return false;
		return /^#?([a-f0-9]{6}|[a-f0-9]{3})$/.test(tag.tag_color)
	}, [tag?.tag_color])
	var text_color = useMemo(()=>{
		if(!valid_hex) return '#000000';
		return getTextColor(tag?.tag_color);
	}, [tag?.tag_color]);
	
	if(!tag) return <Header loading text="Cargando tag" />;
	var onTagEdit = bindFormChange(tag, setTag);

	var submit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(tag, {
			tag_name: [{
				rule: 'length', params: [3, 32], label: 'Nombre'
			}],
			tag_color: [{
				rule: 'length', params: [6, 8], label: 'Color'
			}]
		});

		setErrorPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		if(IS_CREATE){
			API.createTag(tag.tag_name, tag.tag_color).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				setTag(res.data);
				navigate(`/tags/${res.data.tag_id}`, { replace: true });
				Toast.success('Se ha creado el tag!');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado creando el tag (LCL-TGCRT-1)']);
			}).finally(()=>{
				setLoading(false);
			})
		}else{
			API.editTag(tag.tag_id, tag.tag_name, tag.tag_color).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editado el tag');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado creando el tag (LCL-TGEDT-1)']);
			}).finally(()=>{
				setLoading(false);
			})
		}
	}
	
	return <Groupper title={`${IS_CREATE ? 'Crear' : 'Editar'} tag`} width={450} actions={(
		<Button text='Guardar' onClick={submit} color='black' />
	)}>
		<Input label='Nombre' value={tag.tag_name} onChange={onTagEdit('tag_name')} />
		<Input label='Color' value={tag.tag_color?.toUpperCase()} onChange={onTagEdit('tag_color')} />
		<HexColorPicker color={tag.tag_color || '#000000'} onChange={onTagEdit('tag_color')} style={{ width: '100%' }} />
		<Field label='Label' style={{ marginTop: 15 }}>
			<Label style={{ minWidth: 120, padding: 5, backgroundColor: valid_hex ? tag.tag_color : '#E0E1E4', color: text_color }}>
				{tag.tag_name && tag.tag_name.length>0 ? tag.tag_name : <i>Sin nombre</i>}
			</Label>
		</Field>
		<Message list={errorPrompts} type='error' />
	</Groupper>
}

export default TagEdit;