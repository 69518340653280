import React, { useEffect, useState } from 'react';
import { OrderTag } from '../Classes';
import API from '../API';
import { Button, Groupper, Header, Label, Table } from 'react-frontier';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { bindClick, getTextColor } from '../Util';

interface TagListProps{
	
}

var TagList = (props: TagListProps)=>{
	var [loadError, setLoadError] = useState<string>(null);
	// var [addModal, setAddModal] = useState<boolean>(false);
	var [tags, setTags] = useState<OrderTag[]>(null);

	useEffect(()=>{
		API.getTagList().then(res=>{
			if(res.error) return setLoadError(res.message);
			setTags(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error cargando las tags (LCL-TGLST-1)');
		})
	}, []);

	if(loadError) return <Header text={'Error'} subtext={loadError} />
	if(!tags) return <Header loading text={'Cargando tags'} />
	
	return <>
		<Groupper
			title={'Tags'}
			width={450}
			style={{ paddingBottom: 0, overflow: 'hidden' }}
			titleRight={(
				<Button text='Agregar' color='black' iconName='plus-circle' as={Link} to={'/tags/create'} />
			)}
		>
			<Table
				striped fitted selectable
				className='last'
				headers={['ID', 'Tag']}
			>
				{tags.map(a=>{
					var text_color = getTextColor(a.tag_color);
					return (
						<Table.Row 
							compact
							as={Link}
							key={`TGL-${a.tag_id}`} 
							to={`/tags/${a.tag_id}`}
							collapsingIndexes={[0]} 
							data={[
								a.tag_id,
								<Label style={{ minWidth: 120, backgroundColor: a.tag_color, color: text_color }}>{a.tag_name}</Label>
							]} 
						/>
					)
				})}
			</Table>
		</Groupper>
	</>
}

export default TagList;