import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Icon, Checkbox, Button, Input, Header, Message, Field, Table } from 'react-frontier';
import { addCommas, bindClick, bindSimpleFormChange } from '../Util';
import { CodeCategory, CodeCategoryForm, SetLoading } from '../Classes';
import { useTitle } from '../Hooks';
import API from '../API';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

var Zipcodes = ()=>{
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [categories, setCategories] = useState<CodeCategory[]>(null);
	var [createModal, setCreateModal] = useState<boolean>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [createForm, setCreateForm] = useState<CodeCategoryForm>({ name: '', cost: null, local: false });
	var [filter, setFilter] = useState<string>('');

	useEffect(() => {
		get();
		setTitle('Códigos postales')
	}, []);

	var get = ()=>{
		API.getCodeCategories().then(res=>{
			if(res.error) return setLoadError(res.message);
			return setCategories(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener las categorias. (GCC-1)'); 
		})
	}

	var create = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(createForm, {
			name:[{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			cost:[{
				rule: 'number', label: 'Costo'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createCodeCategory(createForm.name, createForm.cost, createForm.local).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Categoria creada!');
			get();
			return setCreateModal(false);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando la categoria (CC-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onCreateFormChange = bindSimpleFormChange(createForm, setCreateForm);

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(categories===null){
		return <Header loading text='Cargando datos...' />
	}

	var search = categories.filter(category => {
		return category.name.toLowerCase().includes(filter.toLowerCase());
	});

	return <div>
		<Table
			style={{ maxWidth: 600, margin: 'auto' }}
			striped
			selectable
			title='CÓDIGOS POSTALES'
			headers={['', 'ID', 'Nombre', 'Costo']}
			collapsingIndexes={[3]}
			button={(
				<Button size='tiny' color='black' iconName='plus-circle' text='Agregar' onClick={bindClick(setCreateModal, true)}/>
			)}
		>
			{search.length === 0 ? <Table.Row><Table.Cell className='empty' colSpan={5}>No se encontraron registros...</Table.Cell></Table.Row> :
				search.map(z => (
					<Table.Row
						compact
						key={z.category_id}
						as={Link}
						to={`/zipcodes/${z.category_id}`}
						collapsingIndexes={[0,1,3]}
						data={[
							<Icon name={z.enabled ? 'check' : 'remove'} color={z.enabled ? 'green' : 'brown'} />,
							// z.enabled ? <Icon color='green' name='check'/> : <Icon color='red' name='close'/>,
							z.category_id,
							z.name,
							'$'+addCommas(z.cost)
						]}
					/>
				))
			}
		</Table>
		<Modal open={createModal} size={'tiny'} onClose={bindClick(setCreateModal)}>
			<Modal.Header>Agregar Categoría</Modal.Header>
			<Modal.Content>
				<Input label='Nombre' value={createForm.name} onChange={onCreateFormChange('name')}/>
				<Input label='Costo' value={createForm.cost} onChange={onCreateFormChange('cost')}/>
				<Field label='Local' comment='Se refiere a si se podrán enviar productos marcados como solo entrega local.'>
					<Checkbox style={{ display:'flex', marginBottom: 10 }} label='Categoría local' checked={createForm.local} onChange={onCreateFormChange('local')}/>
				</Field>
				<Message type="error" list={errorPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' onClick={bindClick(setCreateModal)} basic />
				<Button text='Crear' color='black' onClick={create}/>
			</Modal.Actions>
		</Modal>
	</div>
}

export default Zipcodes;