import { useState, useEffect } from 'react'
import { Groupper, Field, Message, Button, Table, Input } from 'react-frontier';
import moment from 'moment';
import API from '../API';
import Validator from '../Validator';
import { useTitle } from '../Hooks';
import { addCommas } from '../Util';
import { Link } from 'react-router-dom';
import Toast from 'react-hot-toast';
import { Dropdown, Icon } from 'semantic-ui-react';

interface DateInvoices {
	invoice_id: number;
	order_id: number;
	total: number;
	legal_name: string;
	rfc: string;
 }

var InvoicesReport = () => {
   var { setTitle } = useTitle();
   var [startDate, setStartDate] = useState<number>(moment().subtract(7, 'd').unix());
   var [endDate, setEndDate] = useState<number>(moment().unix());
   var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
   var [invoices, setInvoices] = useState<DateInvoices[]>([]);

   useEffect(()=>{
		setTitle('Report de Facturas');
	}, []);


   var showInvoices = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ startDate, endDate }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');

		setLoading(true);
		API.showDateInvoices(mstart, mend).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
         if(res.data.length == 0) setErrorPrompts(['No se encontraron registros.'])
			return setInvoices(res.data);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado buscando las facturas. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}
   
   var viewFactura = (invoice_id: number)=>{
		return ()=>{
			API.downloadInvoice(invoice_id).then(res=>{
				if(res.error) return Toast.error(res.message);
				if(!res.data || !res.data.invoice_url) return Toast.error('Hubo un error cargando la factura (LCL-3)')
				window.open(res.data.invoice_url, '_blank', 'noreferrer');
			}).catch(err=>{
				return Toast.error('Hubo un error cargando la factura. (LCL-3)');
			});
		}
	}

	var downloadFactura = (invoice_id: number)=>{
		return ()=>{
			API.downloadInvoice(invoice_id, true).then(res=>{
				if(res.error) return Toast.error(res.message);
				if(!res.data || !res.data.invoice_url) return Toast.error('Hubo un error cargando la factura (LCL-4)')
				window.open(res.data.invoice_url, '_blank', 'noreferrer');
			}).catch(err=>{
				return Toast.error('Hubo un error cargando la factura. (LCL-4)');
			});
		}
	}

   return <div>
      <Groupper
         title='Report de Facturas'
         width={500}
         actions={
            <Button text='Buscar' color='black' onClick={showInvoices}/>
         }
      >
         <Field amount={2}>
            <Input label='Fecha de inicio' value={startDate} onChange={setStartDate} calendar={{ date: startDate, mode: 'date', format: 'DD/MM/YYYY',}}/>
            <Input label='Fecha de fin' value={endDate} onChange={setEndDate} calendar={{ date: endDate, mode: 'date', format: 'DD/MM/YYYY',}}/>
         </Field>
         <Message list={errorPrompts} type={'error'} />
      </Groupper>
      {invoices.length !== 0 && <Table
            title='Facturas'
            style={{ maxWidth: 800, margin: 'auto', marginTop: 20 }}
            headers={[ 'ID', 'ORDEN', 'NOMBRE', 'RFC', 'CANTIDAD', '']}
            collapsingIndexes={[4, 5]}
            striped
         >
         {invoices.map(i => (
            <Table.Row
               key={i.invoice_id}
               collapsingIndexes={[4,5]}
               data={[
                  i.invoice_id,
                  i.order_id,
                  i.legal_name,
                  i.rfc,
                  '$' + addCommas(i.total),
                  <Dropdown icon={(
                     <div className="fr label" style={{ padding: '3px 10px' }}>
                        <Icon name='ellipsis horizontal' size='small' style={{ margin: 0 }} />
                     </div>
                  )} className='icon'>
                     <Dropdown.Menu>
                        <Dropdown.Item target='_blank' as={Link} to={`/orders/${i.order_id}`}>
                           Ver Orden 
                        </Dropdown.Item>
                        <Dropdown.Item target='_blank' onClick={ viewFactura(i.invoice_id) }>
                           Descargar PDF 
                        </Dropdown.Item>
                        <Dropdown.Item target='_blank' onClick={ downloadFactura(i.invoice_id) }>
                           Descargar ZIP
                        </Dropdown.Item>
                     </Dropdown.Menu>
                  </Dropdown>

               ]}
            />
         ))}
      </Table>}
   </div>
}

export default InvoicesReport