import React, { useEffect, useState } from 'react';
import { Button, Input, Message, Header, Field } from 'react-frontier';
import { CategoryCard } from '@cocoan/components'
import { useNavigate } from "react-router-dom";
import { Modal, Checkbox } from 'semantic-ui-react';
import { CategoryForm, Category, SetLoading } from '../Classes';
import { bindClick, bindFormChange } from '../Util';
import { useTitle } from '../Hooks';
import { firstBy } from 'thenby';
import { arrayMoveImmutable } from 'array-move';
import SortableList, { SortableItem } from "react-easy-sort";
import API from '../API';

import '../style/categories.scss';
import Validator from '../Validator';
import Toast from 'react-hot-toast';

var Categories = ()=>{
	var { setTitle } = useTitle();
	var navigate = useNavigate();
	var [categories, setCategories] = useState<Category[]>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [loadError, setLoadError] = useState<string>(null);
	var [search, setSearch] = useState<string>('');
	var [createModal, setCreateModal] = useState<boolean>(false);
	var [sortModal, setSortModal] = useState<boolean>(false);
	var [sortCategories, setSortCategories] = useState<Category[]>([]);
	var [createForm, setCreateForm] = useState<CategoryForm>({ category_name: '', description: '', online: false, pdv: false, products: null, deleted: 0 });
	var [showAll, setShowAll] = useState<boolean>(false);
	var [loadingAll, setLoadingAll] = useState<boolean>(false);

	useEffect(() => {
		get();
		setTitle('Categorías');
	}, [showAll]);

	var onCreateFormChange = bindFormChange(createForm, setCreateForm);

	var get = ()=>{
		setLoadingAll(true);
		API.getCategories(showAll).then(res=>{
			if(res.error) return setLoadError(res.message);
			setCategories(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener las categorías. (GCE-2)');
		}).finally(()=>{
			setLoadingAll(false);
		})
	};

	var create = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(createForm, {
			category_name:[{
				rule: 'minLength', params: [3], label: 'Nombre'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createCategory(createForm.category_name, createForm.description, createForm.online, createForm.pdv).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Categoría creada!');
			navigate(`/categories/${res.data.category_id}`);
			return setCreateModal(false);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando la categoría (C-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	var showSort = ()=>{
		setSortCategories(categories.filter(a=>(!a.deleted && (a.online || a.pdv))));
		setSortModal(true);
	}

	var saveSort = (setLoading: SetLoading)=>{
		var order = sortCategories.map(a=>a.category_id);
		setLoading(true);
		API.saveCategorySort(order).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Se ha actualizado el orden de las categorías');

			var cats = [...categories];
			for(var i of cats){
				var ix = order.findIndex(a=>a==i.category_id);
				if(ix==-1) i.priority = -1;
				i.priority = ix===-1 ? -1 : order.length-ix;
			}
			setCategories(cats);
		}).catch(err=>{
			Toast.error('Hubo un error actualizando el orden de categorías (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!categories){
		return <Header loading text='Cargando datos' />
	}

	var shown_categories : Category[] = showAll ? categories : categories.filter(a=>(!a.deleted && (a.online || a.pdv)));
	if(search && search.length>=2){
		shown_categories = shown_categories.filter(a=>a.category_name.toLowerCase().indexOf(search.toLowerCase())>-1)
	}

	var onSortEnd = (old: number, newix: number)=>{
		setSortCategories(arr=>arrayMoveImmutable(arr, old, newix));
	}

	return <div className='co categories-screen'>
		<Header text='Categorías' />
		<div className='toolbar'>
			<Input placeholder='Buscar categoría' inputStyle={{ textAlign: 'center' }} onChange={setSearch} />
			<div className='options'>
				<Button text='Agregar' iconName='plus-circle' color='black' onClick={bindClick(setCreateModal, true)} />
				<Button icon iconName='sort' onClick={showSort} />
				<Checkbox toggle style={{display:'flex', marginTop: 5 }} label='Mostrar todas' checked={showAll} onChange={() => setShowAll(!showAll)}/>
			</div>
		</div>
		{loadingAll ? <Header loading text='Cargando datos' /> : <div className="categories">
			{ shown_categories.sort(firstBy('priority', 'desc')).map(a=>(
				<CategoryCard key={`CAT-CRD-${a.category_id}`} category={a} image={`${API.CDN_URL}/categories/${a.category_id}.webp`} placeholderImage={API.PLACEHOLDER_IMG} href={`/categories/${a.category_id}`} />
			))}
		</div>}
		<Modal open={sortModal} className='sort-categories-list' onClose={bindClick(setSortModal, false)}>
			<Modal.Header>Ordenar categorías</Modal.Header>
			<Modal.Content>
				<SortableList
					onSortEnd={onSortEnd}
					className="list"
					draggedItemClassName="sort-categories-list-item-dragged"
				>
					{sortCategories.map(a=>(
						<SortableItem key={`SRT-CAT-${a.category_id}`}>
							<div className="item">
								<CategoryCard category={a} image={`${API.CDN_URL}/categories/${a.category_id}.webp`} placeholderImage={API.PLACEHOLDER_IMG} />
							</div>
						</SortableItem>
					))}
				</SortableList>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClick(setSortModal, false)} />
				<Button text='Guardar' color='black' onClick={saveSort} />
			</Modal.Actions>
		</Modal>
		<Modal open={createModal} size={'tiny'} onClose={bindClick(setCreateModal)}>
			<Modal.Header>Agregar Categoría</Modal.Header>
			<Modal.Content>
				<Input label='Nombre' onChange={onCreateFormChange('category_name')}/>
				<Field label="Description">
					<textarea style={{ width: '100%' }} placeholder='Descripción del producto' rows={5} onChange={onCreateFormChange('description', false, true)} value={createForm.description} />
				</Field>
				<div style={{padding: 5, fontWeight: 'bold' }}>Disponibilidad</div>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='En linea' onChange={() => onCreateFormChange('online')(!createForm.online)}/>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Punto de venta' onChange={() => onCreateFormChange('pdv')(!createForm.pdv)}/>
				<Message type="error" list={errorPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button basic text='Cancelar' onClick={bindClick(setCreateModal)} />
				<Button text='Agregar' color='black' onClick={create}/>
			</Modal.Actions>
		</Modal>
	</div>
}

export default Categories;