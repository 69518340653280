import React, { useEffect, useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { Button, Header, Input, Message, Table } from 'react-frontier';
import { bindFormChange, addCommas, bindClick } from '../Util';
import { Link } from 'react-router-dom';
import API from '../API';
import moment from 'moment';
import Toast from "react-hot-toast";

interface ExternalPayment {
	status: string,
	amount: number,
	date: number,
	reference: string,
	bank: string,
	card_digits: string,
	error: boolean,
	error_message: string,
}

interface PaymentOrderInfo {
	payment_id: number,
	paid: boolean,
	payment_method?: number,
	method_name_localized: string,
	external_id?: string,
	reference?: string,
	date_created: number,
	date_cancelled: number,
	date_paid: number,
	amount: number,
	cancelled: boolean,
	pdv_name: string,
	pdv_id: string,
	external_payment: ExternalPayment,
	payment_link: string,
	shipping_cost?: number
}

interface ModalProps {
	open: boolean,
	editable?: boolean,
	onClose: () => void,
	payment_id: number
	onPaymentDelete?: (paymentInfo: { payment_id: number, deleted: boolean }) => void,
	onPaymentPaid?: (paymentInfo: { payment_id: number, paid: boolean }) => void,
}

var PaymentInfoModal = (props: ModalProps) => {
	let payment_id = props.payment_id || null;
	var [loadError, setLoadError] = useState<string>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [form, setForm] = useState<PaymentOrderInfo>(null);
	var [cancelModal, setCancelModal] = useState<boolean>(false);
	var [paidModal, setPaidModal] = useState<boolean>(false);

	useEffect(() => {
		if (props.open && !Number.isNaN(parseInt(payment_id.toString()))) {
			loadData(payment_id);
		}
	}, [props.open, props.payment_id]);

	const loadData = async (payment_id: number) => {
		setForm(null);
		setLoadError(null);
		API.getPayment(payment_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setForm(res.data);
		}).catch(err => {	
			setModalPrompts(['Hubo un error cargando la cuenta (LCL-3)']);
		})
	}

	const cancelPayment = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.cancelOrderPayment(payment_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Pago cancelado');
			if (props.onPaymentDelete) {
				props.onPaymentDelete({ 
					payment_id: parseInt(payment_id.toString()),
					deleted: true
				});
			}
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado cancelando el pago. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
			setCancelModal(false);
		});
	};

	const paidPayment = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.paidOrderPayment(payment_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Pago actualizado!');
			var f = {...form};
			f.paid = true;
			f.date_paid = moment().unix();
			setForm(f);
			if (props.onPaymentPaid) {
				props.onPaymentPaid({ 
					payment_id: parseInt(payment_id.toString()),
					paid: true
				});
			}
			setPaidModal(false);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado cancelando el pago. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
			setCancelModal(false);
		});
	};

	var onDataChange = bindFormChange(form, setForm);

	return <div>
		<Modal open={props.open} onClose={props.onClose} size='tiny'>
			<Modal.Header>Informacion de Pago</Modal.Header>
			<Modal.Content>
				{loadError ? (
					<Header text='Error' iconName='face-frown-open' subtext={loadError} />
				) : form===null ? (
					<Header loading text='Cargando Pago' />
				) : (form ? <>
					<Table fitted details striped title='Información' titleSize='small' button={
						!form.paid && <Button color='black' size='tiny' text='Marcar pagado' onClick={() => setPaidModal(true)} />
					} >
						<Table.Row data={['ID', form.payment_id]} />
						{!!form.pdv_id &&
							<Table.Row as={Link} to={`/pdv/${form.pdv_id}`} compact data={['PDV', `[${form.pdv_id}] ${form.pdv_name}`]} />
						}
						<Table.Row data={['Estatus', <>
							<div className={`fr label ${form.cancelled ? 'red' : (form.paid ? 'green' : 'blue')}`}>
								{form.cancelled ? 'Cancelado' : (form.paid ? 'Pagado' : 'Pendiente')}
							</div>
						</>]} />

						{form.method_name_localized ?
							<Table.Row data={['Metodo de pago', form.method_name_localized]} /> : null
						}
						<Table.Row data={['Referencia', form.reference || <Icon name='minus' color='grey' />]} />
						{!!form.external_id && (
							<Table.Row>
								<Table.Cell>ID Externo</Table.Cell>
								<Table.Cell>
									<Link className='normal' to={form.payment_link} target="_blank" >
										{form.external_id}
									</Link>
								</Table.Cell>
							</Table.Row>
						)}
						<Table.Divider />
						{form.date_paid ?
							<Table.Row data={['Fecha pago', <>
								{moment.unix(form.date_paid).format('DD/MM/YY HH:mm:ss')}
								<div className="meta">({moment().diff(moment.unix(form.date_paid), 'days')} días)</div>
							</>]} /> : null
						}
						{form.date_cancelled ?
							<Table.Row data={['Fecha cancelado', moment.unix(form.date_cancelled).format('DD/MM/YY HH:mm:ss')]} /> : null
						}
						<Table.Row data={['Total pagado', `$${addCommas(form.amount)}`]} />
					</Table>

					{!!form.external_payment && !form.external_payment.error &&
						<Table fitted details striped style={{ marginTop: 20 }} title='Información externa' titleSize='small'>
							<Table.Row data={['Procesador', form.method_name_localized]} />
							{!form.external_payment.error && (<>
								<Table.Row data={['Referencia', form.external_payment.reference]} />
								<Table.Row data={['Estatus', form.external_payment.status]} />
								<Table.Row data={['Cantidad Pagada', addCommas(form.external_payment.amount)]} />
								<Table.Row data={['Fecha de Pago', moment.unix(form.external_payment.date).format('DD/MM/YY HH:mm:ss')]} />
								<Table.Row data={['Banco', form.external_payment.bank]} />
								<Table.Row data={['Tarjeta', form.external_payment.card_digits]} />
							</>)}
						</Table>
					}
					{!!form.external_payment && !!form.external_payment.error && (
						<Message type='error' list={[form.external_payment.error_message]} />
					)}
				</> : null)}
				<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button basic text='Eliminar' onClick={bindClick(setCancelModal, true)}  color='red'/>
				<Button text='Cerrar' onClick={props.onClose} />
			</Modal.Actions>
		</Modal>
		<Modal open={cancelModal} onClose={bindClick(setCancelModal)} size='mini'>
			<Modal.Header>Eliminar pago</Modal.Header>
			{form && <Modal.Content>
				<Header text={`¿Eliminar pago?`} style={{ margin: 0 }} />
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={[
						['Pago', form.payment_id],
						['Metodo', form.method_name_localized],
						['Monto', '$'+addCommas(form.amount)]
					]}
				/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setCancelModal)} text='Cerrar' basic/>
				<Button onClick={cancelPayment} color='red' text='Eliminar' />
			</Modal.Actions>
		</Modal>
		<Modal open={paidModal} onClose={bindClick(setPaidModal)} size='mini'>
			<Modal.Header>Marcar como pagado</Modal.Header>
			{form && <Modal.Content>
				<Header text={`¿Marcar como pagado?`} style={{ margin: 0 }} />
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={[
						['Pago', form.payment_id],
						['Metodo', form.method_name_localized],
						['Monto', '$'+addCommas(form.amount)]
					]}
				/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setPaidModal)} text='Cerrar' basic/>
				<Button onClick={paidPayment} color='red' text='Aceptar' />
			</Modal.Actions>
		</Modal>
	</div>
}

export default PaymentInfoModal;