import React, { useEffect, useRef, useState } from 'react';
import { Toolbar, NotFound, UserLog } from '../components';
import { Button, Input, Message, Header, Groupper, Field, Table } from 'react-frontier';
import { Dropdown, Modal, Icon, ModalActions, Checkbox } from 'semantic-ui-react';
import { useParams } from "react-router-dom";
import API from '../API';
import { bindClick, bindSimpleFormChange, bindFormChange } from '../Util';
import { UserLoginData, SetLoading, UserData, UserAccess} from '../Classes';
import moment from 'moment';

import Validator from '../Validator';
import Toast from 'react-hot-toast';
import classNames from 'classnames';

import '../style/categories.scss'
import { useTitle } from '../Hooks';

const user_access = [
	{ access_id: 0, access_name: 'Sin accesso' },
	{ access_id: 1 , access_name: 'Cajero' },
	{ access_id: 2 , access_name: 'Reportes' },
	{ access_id: 10 , access_name: 'Admin' }
]

var User = ()=>{
	var { user_id } = useParams();
	var { setTitle } = useTitle();
	var imageInputRef = useRef<HTMLInputElement>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [userLogged, setUserLogged] = useState<UserLoginData>(null);
	var [user, setUser] = useState<UserData>(null);
	var [newPassword, setNewPassword] = useState<string>('');
	var [viewingImage, setViewingImage] = useState<number>(null);
	var [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);
	var [addingImage, setAddingImage] = useState<boolean>(false);
	var [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
	var [deleteModal, setDeleteModal] = useState<boolean>(false);

	useEffect(() => {
		setUserLogged(API.getLogin());
		get();
	}, []);

	var get = ()=>{
		API.getUser(parseInt(user_id)).then(res=>{
			if (res.error) return setLoadError(res.message);
			setTitle(`Usuario ${res.data.alias.toUpperCase()}`);
			return setUser(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error al obtener el usuario. (GUS-1)');
		});
	};

	var update = (setLoading: (l: boolean)=>void)=>{
		var validationRules: { [key: string]: any } = {
			first_name: [{
				rule: 'minLength',
				params: [3],
				label: 'Nombre',
			}],
			last_name: [{
				rule: 'minLength',
				params: [3],
				label: 'Apellido',
			}],
			email: [{
				rule: 'email',
				label: 'Correo electrónico',
			}],
			alias: [{
				rule: 'minLength',
				params: [3],
				label: 'Alias',
			}],
			...(user.show_public ? {
				department: [{
					rule: 'minLength',
					params: [3],
					label: 'Puesto',
				}]
			} : {}),
		}; 
		var { valid, prompts } = Validator(user, validationRules);
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.updateUser(user.user_id, user.first_name, user.last_name, user.email, user.alias, user.access, user.department, user.show_public).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Usuario actualizado!');
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado actualizando el usuario (U-UPD-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	};

	var changePasswordUser = (setLoading: (l: boolean)=>void)=>{
		if(newPassword.length<6) return Toast.error('La contraseña debe tener al menos 6 caracteres');
		setLoading(true);
		API.changeUserPassword(parseInt(user_id), newPassword).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Contraseña actualizada!');
			return setChangePasswordModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado actualizando la contraseña (U-UPD-1)')
		}).finally(()=>{
			setNewPassword(null);
			setLoading(false);
		});
	}

	var addImage = ()=>{
		imageInputRef.current?.click();
	}

	var handleImage = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(!ev.target.files || ev.target.files.length==0) return;
		if(ev.target.files.length>1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if(image.size>(2.5*1024*1024)) return Toast.error('La imagen no puede pesar mas de 2.5MB.');

		setAddingImage(true);
		API.addUserImage(parseInt(user_id), image).then(res=>{
			if(res.error) return Toast.error(res.message);
			
			var u = {...user};
			if(!u.image) u.image = null;
			if(!u.image){
				u.image = res.data.image_url;
			}
			setUser(u);

			Toast.success('Se ha agregado la imagen del usuario');
		}).catch(err=>{
			return Toast.error('Hubo un error subiendo la imagen del usuario. (LCL-1)');
		}).finally(()=>{
			setAddingImage(false);
		});
	}

	var viewImage = (image: number)=>{
		return ()=>{
			setViewingImage(image)
		}
	}

	var deleteImage = (image: number)=>{
		return (setLoading: SetLoading)=>{
			setLoading(true);
			API.deleteUserImage(user.user_id).then(res=>{
				if(res.error) return Toast.error(res.message);

				var u = {...user};
				u.image = res.data.image;
				setUser(u);
				Toast.success('Se ha eliminado la imagen del usuario.');
				setDeleteImageModal(false);
				setViewingImage(null);
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado eliminando la imagen. (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var deleteUser = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.deleteUser(parseInt(user_id), !user.deleted).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('usuario actualizado!');
			user.deleted = !user.deleted;
			if(user.deleted){
				user.date_deleted = moment().unix();
				user.user_deleter_name = userLogged.name_complete;
			}
			return setDeleteModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado actualizando el usuario. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onEditChange = bindFormChange(user, setUser);

	if(Number.isNaN(parseInt(user_id))){
		return <NotFound />
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!user || !userLogged){
		return <Header loading text='Cargando datos' />
	}
	
	return <div>
		{(userLogged.access === UserAccess.SUPER_ADMIN && user.access !== UserAccess.SUPER_ADMIN) || userLogged.user_id === parseInt(user_id) ? (
			<Toolbar
				style={{ width: '100%', maxWidth: 500, margin: 'auto', marginBottom: 5 }}
				items={[
					{ text: 'Cambiar contraseña', icon: 'key', onClick: () => setChangePasswordModal(true) },
				]}
			/>
		) : null}
		<Groupper title={'Editar usuario'} width={500} titleCentered actions={(
			<div>
				{userLogged.access === UserAccess.SUPER_ADMIN ? <Button text="Eliminar" color='red' style={{ marginRight: 5 }} onClick={ ()=>setDeleteModal(true) }/> : user.deleted ? <Button text="Restaurar" color='green' style={{ marginRight: 5 }} onClick={ ()=>setDeleteModal(true) }/> : null}
				<Button text="Guardar" color="black" onClick={update}/>
			</div>
		)}>
			<div className="section head" style={{ marginTop: -21 }}>Imagen</div>
			<input type="file" style={{ display: 'none' }} ref={imageInputRef} accept="image/*" onChange={handleImage}/>
			<div className="co single product portrait images">
				{!!user.image ? (
					<img src={user.image} className="image" onClick={viewImage(-1)} />
				) : (
					<div className={classNames('image', {
						add: !addingImage,
						loading: addingImage,
					})} onClick={addImage}>
						{addingImage ? (
							<div className="fr loading inline"></div>
						) : (
							<Icon name="plus" />
						)}
					</div>
				)}
			</div>
			<div className="section head">Detalles</div>
			<Field amount={2}>
				<Input label='Nombre' onChange={onEditChange('first_name')} value={user.first_name}/>
				<Input label='Apellido' onChange={onEditChange('last_name')} value={user.last_name}/>
			</Field>
			<Field amount={2}>
				<Input label='Email' onChange={onEditChange('email')} value={user.email}/>
				<Input label='Alias' onChange={onEditChange('alias')} value={user.alias}/>
			</Field>
			{userLogged && userLogged.access == UserAccess.SUPER_ADMIN && user.access !== UserAccess.SUPER_ADMIN ? (
				<Field label='Acceso'>
					<Dropdown
						placeholder='Accesso'
						fluid
						selection
						options={user_access.map(access => ({
							key: access.access_id,
							text: access.access_name,
							value: access.access_id
						}))}
						style={{marginBottom: 10}}
						value={user.access}
						onChange={(event, data) => onEditChange('access')(data.value)}
					/> 
				</Field>
			) : null}
			<Checkbox style={{ marginTop: 15, marginBottom: 15 }} toggle label='Mostrar en pagina publica' checked={!!user.show_public} onChange={onEditChange('show_public', true)}/>
			{!!user.show_public && (
				<Input label='Puesto' placeholder='Puesto que se mostrará en la página publica' value={user.department} onChange={onEditChange('department')} />
			)}
			{user.access === UserAccess.SUPER_ADMIN && (
				<Message type="info" list={['Este usuario es un Super Admin.']} style={{ marginBottom: 10 }} />
			)}
			<Message type="error" list={errorPrompts} />
		</Groupper>
		<UserLog user={user}/>
		<Modal open={changePasswordModal} size='mini' onClose={bindClick(setChangePasswordModal)}>
			<Modal.Header>Cambiar contraseña</Modal.Header>
			<Modal.Content>
				<Input label='Contraseña' value={newPassword} onChange={setNewPassword}/>
				<Message type="error" list={errorPrompts}/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' onClick={bindClick(setChangePasswordModal)} basic />
				<Button text='Guardar' onClick={changePasswordUser} color='black'/>
			</Modal.Actions>
		</Modal>
		<Modal open={viewingImage!==null && !deleteImageModal} size='mini' onClose={bindClick(setViewingImage)}>
			<Modal.Header>Imagen</Modal.Header>
			{viewingImage!==null && <Modal.Content>
				<img 
					src={ user.image }
					style={{ width: '100%', maxWidth: 400, display: 'block', margin: 'auto' }} 
				/>
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setDeleteImageModal, true)} color='red' text='Eliminar' />
				<Button onClick={bindClick(setViewingImage)} text='Cerrar'/>
			</Modal.Actions>
		</Modal>
		<Modal open={viewingImage!==null && deleteImageModal} size='mini' onClose={bindClick(setDeleteImageModal)}>
			<Modal.Header>Eliminar imagen</Modal.Header>
			{viewingImage!==null && deleteImageModal && <Modal.Content>
				<div className="co product portrait single images" style={{ justifyContent: 'center' }}>
					<img src={user.image} className='image' />
				</div>
				<Header text='¿Eliminar imagen?' style={{ marginTop: 15, marginBottom: 0 }}/>
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setDeleteImageModal)} text='Cancelar' basic />
				<Button onClick={deleteImage(viewingImage)} color='red' text='Eliminar' />
			</ModalActions>
		</Modal>
		<Modal open={deleteModal} onClose={bindClick(setDeleteModal)} size='mini'>
			<Modal.Header>Confirmación</Modal.Header>
			<Modal.Content>
				<Header text={`¿ ${!user.deleted ? 'Eliminar' : 'Restaurar'} usuario?`} />
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={ [
						['Nombre', user.first_name + ' ' +user.last_name],
						['Alias', user.alias],
					]}
				/>
			</Modal.Content>
			<ModalActions>
				<Button onClick={bindClick(setDeleteModal)} text='Cancelar' basic/>
				<Button onClick={deleteUser} color={user.deleted ? 'green' : 'red'} text={user.deleted ? 'Restaurar' : 'Eliminar'} />
			</ModalActions>
		</Modal>
	</div>
}

export default User;